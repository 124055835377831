body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.nal{
  cursor: not-allowed;
}
.custom-tab-title {
  background-color: blue;
  /* Default background color */
  color: white;
  text-shadow: 0 4px 2px black;
  margin: 0 50px;
  font-weight: 900;
}
.br{
  border-radius: 30px;
}

.custom-tab-title .nav-link.active {
  background-color: blue;
  /* Active tab background color */
  color: white;
  /* Text color for active tab */
}

/* Additional styles for better presentation */
.nav-tabs .nav-link {
  border: none;
  /* Remove default border */
}

.nav-tabs .nav-link.active {
  border: none;
  /* Remove default active border */
}
.resp{
  
  width: 50%;
  
}

@media screen and (max-width:798px) {
  .resp{
  
    width: 75%;
    
  }
}
.t5{
  
  text-align: center;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
