
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
.tida{
  text-shadow: 0 5px 3px black;
}
.text-won{
  color:chartreuse;
}
.text-lose{
  color:blanchedalmond
}
.tida:hover{
  font-weight:bolder;
}
.rod{
  text-shadow: 0 5px 4px purple;
}
.ddiv:hover{
  border:6px solid yellow;
  border-radius: 20px;
  cursor: pointer;
}
.ddiv img{
  text-align: center;
  place-items: center;
  

}
.baby{
  height: 30px;
  width: 50px;
}
.move {
  position: relative;
  animation: moveAnimation 4s linear infinite
}

@keyframes moveAnimation {
  0% {
    left: -20px;
  }
  100% {
    left: calc(100% - 100px); /* Adjust 100px to the width of your div */
  }
}
.moveb {
  position: relative;
  animation: moveAnimationb 2.5s linear infinite
}

.movex {
  position: relative;
  animation: movex .3s linear infinite
}
@keyframes movex {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
  
}

@keyframes moveAnimationb {
  0% {
    left: -50px;
  }
  50% {
    left: calc(10% - 10px); /* Adjust 100px to the width of your div */
  }
  100% {
    left: -50px;
  }
}
.white{
  background-color: white;
  text-align: center;
  color: #008af8;
  font-size: 20px;
  font-weight: bolder;
  text-shadow: 2px 2px 2px yellowgreen;
  text-transform: capitalize;

}
.whiteb{
  background-color: white;
  animation: wako 2.3s linear infinite;
  font-weight: bolder;
  text-shadow: 2px 3px 4px black;
  color: red;

}
@keyframes wako {
  from{
    background-color: white;
  }
  to{
    background-color: coral;

  }
  to{
    background-color:blue;

  }
  /* to{
    background-color: black;

  } */
}
.blackb{
  /* background-color: black; */
  animation: bako 2.3s linear infinite;
  font-weight: bolder;
  text-shadow: 2px 3px 4px black;
  color: greenyellow;

}
::-webkit-scrollbar{
  visibility: hidden;
}
.dimagej{
  object-fit: cover;
}

.table-container {
  width: 100%; /* Set the width of the table container */
  overflow-x: auto; /* Enable horizontal scrolling */
  
}
.rela{
  border-radius: 25px;
  box-shadow: -5px 15px 12px black;
}

.table th {
  background-color: #f0f0f0; /* Header background color */
  position: sticky;
  top: 0;
  z-index: 1;
}
.black{
  background-color: black;
  text-align: center;
  color: #008af8;
  font-size: 20px;
  font-weight: bolder;
  text-shadow: 2px 2px 2px yellowgreen;
  text-transform: capitalize;
  

}
@keyframes bako {

  
  
  /* from{
    background-color: black;

  } */
  to{
    background-color: red;

  }
  to{
    background-color: coral;

  }
  to{
    background-color: white;
  }
}
.dicex {
  display: inline-block;
  font-size: 4rem;
  animation: rollDice .5s linear infinite;
}

@keyframes rollDice {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.dodo{
  max-width: 198px;
}

.clearfix {
  display: inline-block;
}


html[xmlns] .clearfix {
  display: block;
}
.gdiv{

  height: '30vh';
  background-repeat: 'no-repeat';
  background-size: cover;
}
@media screen and (max-width: 768px){
  .gdiv{

  height: '15vh';
  background-repeat: 'no-repeat';
  background-size: cover;
  margin-top: 0;
}
}
* html .clearfix {
  height: 1%;
}
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
.cursor-pointer{
  cursor: pointer;
}
.blinking-text {
  animation: blink 1s linear infinite;
}
.modalf {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Poppins', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  color: #1e1e1e;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 14px;
  line-height: 28px;
  color: #4a4a4a;
}

img {
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
Global Styles
--------------------------------------------- 
*/
html,
body {
  font-family: 'Poppins', sans-serif;
}

::selection {
  background: #0071f8;
  color: #fff;
}

::-moz-selection {
  background: #0071f8;
  color: #fff;
}

.section {
  margin-top: 120px;
}

.section-heading {
  margin-bottom: 70px;
}

.section-heading h2 {
  font-size: 36px;
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 20px;
  line-height: 44px;
}

.section-heading h2 em {
  font-style: normal;
  color: #0071f8;
}

.section-heading h6 {
  color: #ee626b;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
}

.main-button a {
  display: inline-block;
  background-color: #ee626b;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0px 30px;
  border-radius: 25px;
  transition: all .3s;
}

.main-button a:hover {
  background-color: #0071f8;
  color: #fff;
}

/* 
---------------------------------------------
Pre-loader Style
--------------------------------------------- 
*/

.js-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.99);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.js-preloader.loaded {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@-webkit-keyframes dot {
  50% {
      -webkit-transform: translateX(96px);
      transform: translateX(96px);
  }
}

@keyframes dot {
  50% {
      -webkit-transform: translateX(96px);
      transform: translateX(96px);
  }
}

@-webkit-keyframes dots {
  50% {
      -webkit-transform: translateX(-31px);
      transform: translateX(-31px);
  }
}

@keyframes dots {
  50% {
      -webkit-transform: translateX(-31px);
      transform: translateX(-31px);
  }
}

.preloader-inner {
  position: relative;
  width: 142px;
  height: 40px;
  background: transparent;
}

.preloader-inner .dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  background: #0071f8;
  border-radius: 50%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-animation: dot 2.8s infinite;
  animation: dot 2.8s infinite;
}

.preloader-inner .dots {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  -webkit-animation: dots 2.8s infinite;
  animation: dots 2.8s infinite;
}

.preloader-inner .dots span {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  background: #0071f8;
  border-radius: 50%;
}



/* 
---------------------------------------------
Header Style
--------------------------------------------- 
*/

.background-header {
  background-color: #0071f8 !important;
  border-radius: 0px 0px 25px 25px;
  height: 80px!important;
  position: fixed!important;
  top: 0!important;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.15)!important;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.background-header .logo img {
  max-width: 110px;
}

.header-area {
  position: absolute;
  background-color: transparent;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.header-area .main-nav {
  background: transparent;
  display: flex;
}

.header-area .main-nav .logo {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  display: inline-block;
}

.header-area .main-nav .logo h1 {
  font-size: 36px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid rgba(250, 250, 250, 0.3);
}

.background-header .main-nav .logo{
  margin-top: 18px;
}

.header-area .main-nav ul.nav {
  border-radius: 0px 0px 25px 25px;
  flex-basis: 100%;
  margin-top: 10px;
  justify-content: right;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.header-area .main-nav .nav li {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  line-height: 40px;
}

.header-area .main-nav .nav li a {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  font-weight: 300;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  text-transform: capitalize;
  color: #fff;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border: transparent;
  letter-spacing: .25px;
}

.header-area .main-nav .nav li:last-child a {
  background-color: #ee626b;
  text-transform: uppercase;
  font-weight: 500;
}

.header-area .main-nav .nav li:last-child:hover a {
  background-color: #ee626b;
}

.header-area .main-nav .nav li:hover a {
  background-color: rgba(255, 255, 255, 0.10);
}

.header-area .main-nav .nav li a.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.10);
}

.background-header .main-nav .nav li a.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.10);
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 20px;
  display: none;
}

.background-header .main-nav .menu-trigger {
  top: 22px;
}

.background-header .main-nav ul.nav {
  margin-top: 20px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #fff;
}

.header-area.header-sticky .nav li a.active {
  color: #fff;
}

.visible{
  display:inline !important;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 5px;
    padding-right: 5px;
  }

}

@media (max-width: 767px) {
  
  .background-header .main-nav {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 25px 25px;
    width: 100%;
  }
  .background-header .main-nav .nav,
  .header-area .main-nav .nav {
    background-color: #fff;
  }
  .background-header .main-nav .nav li a,
  .header-area .main-nav .nav li a {
    line-height: 50px;
    height: 50px;
    font-weight: 400;
    color: #1e1e1e;
    background-color: #fff;
     border-radius: 0px 0px 25px 25px;
  }
  .background-header .main-nav .nav li,
  .header-area .main-nav .nav li {
    border-top: 1px solid #ddd;
    background-color: #f1f0fe;
    height: 50px;
    border-radius: 0px 0px 25px 25px;
  }
  .background-header .main-nav .nav li:last-child,
  .header-area .main-nav .nav li:last-child {
    border-radius: 0px 0px 25px 25px;
  }
  .header-area .main-nav .nav {
    height: auto;
    flex-basis: 100%;
  }
  .header-area .main-nav .logo {
    position: absolute;
    left: 30px;
    top: 0px;
  }
  .background-header .main-nav .logo {
    top: 0px;
  }
  .background-header .main-nav .border-button {
    top: 0px !important;
  }
  .header-area .main-nav .border-button {
    position: absolute;
    top: 15px;
    right: 70px;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #ee626b!important;
    opacity: 1;
  }
  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }
  .header-area .nav li:last-child a {
    background-color: transparent !important;
    font-weight: 300 !important;
    text-transform: capitalize !important;
  }
  .header-area {
    background-color: transparent;
    padding: 0px 15px;
    height: 80px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 0px;
    margin-top: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .logo img{
    max-width: 70px;
  }
  .logo h1{
    font-size: 20px;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area.header-sticky .nav {
    margin-top: 100px !important;
  }
  .background-header.header-sticky .nav {
    margin-top: 80px !important;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
}

/* 
---------------------------------------------
Banner Style
--------------------------------------------- 
*/

.main-banner {
  background-image: url('../public/images/banner-bg.jpg');
  border-radius: 0px 0px 150px 150px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 85px 0px;
}
.main-bannerc {
  background-image: url('../public/images/banner-bg.jpg');
  border-radius: 0px 0px 150px 150px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5px 0px;
}
.main-bannerb {
  background-image: url('../public/images/banner-bg.jpg');
  border-radius: 0px 0px 150px 150px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5px 0px;
}
.cpp{
  cursor: pointer;
}
.jcenter{
  display: flex;
  justify-content: center;
  align-items: center;
}
.jalign{
  
  align-items: center;
}

.bala{
  /* background-image: url('../public/images/dolo.gif'); */
  background-image: url('https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcW5hdDh1Z2R2eGV0b3ZicHRtZ3Nxc3p6M2YzY3c0aGkzdTJtcTR6ZSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3oGRFlpAW4sIHA02NW/giphy.gif');
  background-repeat: no-repeat;
  background-size: cover;
  width:100%;
  cursor: pointer;
  height: 45vh;
}

.grabb{
  color: gold;
  text-shadow: 2px 2px 2px greenyellow;
  
}
.tanya{
  cursor:pointer
  
}
.dpapa{
  display: flex;
  justify-content: center;
  align-items: center;
}
.jevenly{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.jbtw{
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.jend{
  display: flex;
  justify-content:end;
  align-items: center;
}

.main-banner .caption h6 {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.main-banner .caption h2 {
  font-size: 48px;
  color: #fff;
  margin-top: 20px;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 40px;
}

.main-banner .caption h2:after {
  position: absolute;
  background-image: url(../public/images/caption-dec.png);
  width: 202px;
  height: 12px;
  content: '';
  left: 125px;
  bottom: 0;
}

.main-banner .caption p {
  color: #fff;
  margin-bottom: 70px;
}

.main-banner .caption form {
  position: relative;
  max-width: 450px;
}

.main-banner .caption form input {
  max-width: 450px;
  width: 100%;
  height: 50px;
  outline: none;
  border-radius: 25px;
  background-color: #fff;
  border: none;
  padding: 0px 25px;
  font-size: 14px;
  color: #7a7a7a;
}

.main-banner .caption form button {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  background-color: #ee626b;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0px 25px;
  border: none;
  border-radius: 25px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all .3s;
}

.main-banner .caption form button:hover {
  background-color: #0071f8;
}

.main-banner .right-image {
  position: relative;
}

.main-banner .right-image img {
  border-radius: 25px;
}

.main-banner .right-image span.price {
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 25px;
  background-color: #008af8;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  padding: 4px 15px;
}

.main-banner .right-image span.offer {
  position: absolute;
  left: -25px;
  bottom: -25px;
  border-radius: 50%;
  background-color: #ee626b;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
}



/* 
---------------------------------------------
Services Style
--------------------------------------------- 
*/

.features {
  margin-top: -80px;
}

.features .item {
  padding: 35px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  background-color: #fff;
  text-align: center;
}

.features .item h4 {
  font-size: 17px;
  margin-top: 25px;
  text-transform: uppercase;
}

.features .item .image {
  width: 90px;
  height: 90px;
  display: inline-block;
  text-align: center;
  line-height: 90px;
  background-color: #0071f8;
  border-radius: 50%;
  transition: all .3s;
}

.features .item:hover .image {
  background-color: #ee626b;
}



/* 
---------------------------------------------
Trending Style
--------------------------------------------- 
*/

.trending .main-button {
  margin-top: 32px;
  text-align: right;
}

.trending .item  {
  background-color: #eeeeee;
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 30px;
}

.trending .item .thumb {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}

.trending .item span.price em {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration: line-through;
  display: block;
}

.trending .item span.price {
  text-align: right;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 10px;
  background-color: #008af8;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  padding: 8px 15px 6px 15px;
}

.trending .item .down-content {
  padding: 25px;
}

.trending .item .down-content span.category {
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 5px;
  display: inline-block;
}

.trending .item .down-content h4 {
  font-size: 17px;
  font-weight: 600;
  transition: all .3s;
}

.trending .item .down-content a {
  position: absolute;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #ee626b;
  color: #fff;
  border-radius: 50%;
  right: 25px;
  bottom: 25px;
  transition: all .3s;
}

.trending .item:hover .down-content h4 {
  color: #0071f8;
}

.trending .item:hover .down-content a {
  background-color: #0071f8;
}


/* 
---------------------------------------------
Most Played Style
--------------------------------------------- 
*/

.most-played {
  background-color: #f7f7f7;
  padding: 100px 0px;
  border-radius: 150px;
}

.most-played .main-button {
  margin-top: 32px;
  text-align: right;
}

.most-played .item  {
  background-color: #fff;
  position: relative;
  border-radius: 25px;
  text-align: center;
  margin-bottom: 40px;
}

.most-played .item .thumb {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}

.most-played .item .down-content {
  padding: 20px 15px 40px 15px;
}

.most-played .item .down-content span.category {
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 5px;
  display: inline-block;
}

.most-played .item .down-content h4 {
  font-size: 17px;
  font-weight: 600;
  transition: all .3s;
}

.most-played .item .down-content a {
  position: absolute;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  height: 40px;
  padding: 0px 15px;
  background-color: #ee626b;
  color: #fff;
  border-radius: 20px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
  transition: all .3s;
}

.most-played .item:hover .down-content h4 {
  color: #0071f8;
}

.most-played .item:hover .down-content a {
  background-color: #0071f8;
}


/* 
---------------------------------------------
Categories Style
--------------------------------------------- 
*/

.categories .main-button {
  margin-top: 30px;
}

.categories .item  {
  background-color: #0071f8;
  position: relative;
  border-radius: 25px;
  text-align: center;
  margin-bottom: 30px;
}

.categories .item .thumb {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}

.categories .item h4 {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  padding: 22px;
  margin-bottom: 0px;
}

/* 
---------------------------------------------
CTA Style
--------------------------------------------- 
*/

.cta {
  position: relative;
}

.cta::after {
  background-image: url(../public/images/cta-bg.jpg);
  max-width: 550px;
  max-height: 510px;
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  border-radius: 25px;
  left: 50%;
  transform: translateX(-275px);
  top: 0;
  z-index: -1;
}

.cta .shop {
  margin-top: 80px;
  background-color: #f7f7f7;
  border-radius: 25px;
  padding: 80px;
}

.cta .shop .section-heading {
  margin-right: 30px;
  margin-bottom: 50px;
}

.cta .shop p {
  font-weight: 600;
  font-size: 16px;
}

.cta .shop .main-button {
  margin-top: 50px;
}

.cta .subscribe {
  background-color: #f7f7f7;
  border-radius: 25px;
  padding: 80px 60px;
}

.cta .subscribe .section-heading {
  margin-bottom: 50px;
}

.cta .subscribe form {
  position: relative;
  max-width: 100%;
}

.cta .subscribe form input {
  max-width: 100%;
  width: 100%;
  height: 50px;
  outline: none;
  border-radius: 25px;
  background-color: #fff;
  border: none;
  padding: 0px 25px;
  font-size: 14px;
  color: #7a7a7a;
}

.cta .subscribe form button {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  background-color: #ee626b;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0px 25px;
  border: none;
  border-radius: 25px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all .3s;
}

.cta .subscribe form button:hover {
  background-color: #0071f8;
}


/* 
---------------------------------------------
Footer Style
--------------------------------------------- 
*/

footer {
  margin-top: 150px;
  background-color: #0071f8;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../public/images/footer-bg.jpg);
  min-height: 150px;
  border-radius: 150px 150px 0px 0px;
}

footer p {
  text-align: center;
  line-height: 90px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

footer p a {
  color: #fff;
  transition: all .3s;
  position: relative;
  z-index: 3;
}

footer p a:hover {
  opacity: 0.75;
}


/* 
---------------------------------------------
Page Header Style
--------------------------------------------- 
*/

.page-heading {
  background-image: url(../public/images/page-heading-bg.jpg);
  border-radius: 0px 0px 150px 150px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 0px 100px 0px;
  text-align: center;
}

.page-heading span {
  display: inline-block;
  font-size: 16px;
  color: #fff;
}

.page-heading span a {
  color: #fff;
}

.page-heading h3 {
  font-size: 48px;
  text-transform: uppercase;
  color: #fff;
  margin-top: 20px;
  position: relative;
  margin-bottom: 20px;
}


/* 
---------------------------------------------
Shop Page Style
--------------------------------------------- 
*/

.trending ul.trending-filter {
  list-style: none;
  text-align: center;
  margin-bottom: 70px;
}

.trending ul.trending-filter li {
  display: inline-block;
  margin: 5px 8px;
}

.trending ul.trending-filter li a {
  display: inline-block;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: #1e1e1e;
  background-color: #eee;
  padding: 8px 20px;
  border-radius: 25px;
  transition: all .3s;
}

.trending ul.trending-filter li a.is_active {
  background-color: #ee626b;
  color: #fff;
}

.trending ul.trending-filter li a.is_active:hover {
  color: #fff;
}

.trending ul.trending-filter li a:hover {
  color: #ee626b;
}

.trending-box .item  {
  background-color: #eeeeee;
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 30px;
}

.trending-box .item .thumb {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}

.trending-box .item span.price em {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration: line-through;
  display: block;
}

.trending-box .item span.price {
  text-align: right;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 10px;
  background-color: #008af8;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  padding: 8px 15px 6px 15px;
}

.trending-box .item .down-content {
  padding: 25px;
}

.trending-box .item .down-content span.category {
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 5px;
  display: inline-block;
}

.trending-box .item .down-content h4 {
  font-size: 17px;
  font-weight: 600;
  transition: all .3s;
}

.trending-box .item .down-content a {
  position: absolute;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #ee626b;
  color: #fff;
  border-radius: 50%;
  right: 25px;
  bottom: 25px;
  transition: all .3s;
}

.trending-box .item:hover .down-content h4 {
  color: #0071f8;
}

.trending-box .item:hover .down-content a {
  background-color: #0071f8;
}

.trending ul.pagination {
  margin-top: 50px;
  text-align: center;
  width: 100%;
  display: inline-block;
}

.trending ul.pagination li {
  display: inline-block;
  margin: 0px 5px;
}

.trending ul.pagination li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #eee;
  color: #1e1e1e;
  font-size: 15px;
  font-weight: 600;
  border-radius: 50%;
  transition: all .3s;
}

.trending ul.pagination li a:hover,
.trending ul.pagination li a.is_active {
  background-color: #0071f8;
  color: #fff;
}


/* 
---------------------------------------------
Single Page Style
--------------------------------------------- 
*/

.single-product .sep {
  width: 100%;
  height: 1px;
  background-color: #eee;
  margin: 80px 0px;
}

.single-product .left-image {
  margin-right: 80px;
}

.single-product .left-image img {
  border-radius: 25px;
}

.single-product h4 {
  font-size: 27px;
  margin-bottom: 20px;
}

.single-product span.price {
  font-size: 27px;
  font-weight: 700;
  color: #0071f8;
}

.single-product span.price em {
  font-style: normal;
  color: #c8c8c8;
  font-size: 17px;
  text-decoration: line-through;
  font-weight: 500;
  margin-right: 5px;
}

.single-product p {
  margin-top: 50px;
  margin-bottom: 60px;
}

.single-product form input {
  width: 80px;
  height: 50px;
  background-color: #f7f7f7;
  border: 1px solid #e7e7e7;
  border-radius: 25px;
  text-align: center;
  float: left;
  margin-right: 15px;
}

.single-product form {
  display: inline;
}

.single-product form button {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  background-color: #ee626b;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0px 25px;
  border: none;
  border-radius: 25px;
  transition: all .3s;
}

.single-product form button i {
  margin-right: 5px;
}

.single-product form button:hover {
  background-color: #0071f8;
}

.single-product ul {
  margin-top: 60px;
}

.single-product ul li {
  display: block;
  margin: 20px 0px;
  font-size: 14px;
  color: #0071f8;
}

.single-product ul li:last-child {
  margin-bottom: 0px;
}

.single-product ul li span {
  width: 120px;
  display: inline-block;
  color: #aaa;
}

.tabs-content {
  background-color: #f7f7f7;
  border-radius: 25px;
  padding: 60px;
  transition: all 0.3s;
}

.tabs-content .nav-link {
  border: none;
  border-radius: 0px;
  background-color: transparent !important;
  font-size: 20px;
  font-weight: 600;
  color: #1e1e1e;
  padding: 0px;
}

.tabs-content ul.nav-tabs {
  border-bottom: none;
}

.tabs-content ul.nav-tabs li {
  border-right: 1px solid #d7d7d7;
  margin-right: 30px;
  padding-right: 30px;
}

.tabs-content ul.nav-tabs li:last-child {
  border-right: none;
  margin-right: 0px;
  padding-right: 0px;
}

.tabs-content .nav-tabs .nav-link.active {
  color: #0071f8;
}

.tabs-content .tab-content {
  margin-top: 30px;
}

.related-games .main-button {
  text-align: right;
  margin-top: 32px;
}


/* 
---------------------------------------------
Contact PAge Style
--------------------------------------------- 
*/

.contact-page .left-text {
  background-color: #f7f7f7;
  border-radius: 25px;
  padding: 60px;
}

.contact-page .left-text .section-heading {
  margin-bottom: 50px;
}

.contact-page .left-text p {
  margin-bottom: 50px;
}

.contact-page .left-text ul li {
  display: block;
  font-size: 16px;
  color: #aaaaaa;
  font-size: 300;
  margin-bottom: 30px;
}

.contact-page .left-text ul li:last-child {
  margin-bottom: 0px;
}

.contact-page .left-text ul li span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
  margin-bottom: 5px;
}

.contact-page .right-content {
  margin-left: 50px;
}

.contact-page .right-content #map {
  margin-bottom: 60px;
}

.contact-page .right-content form input {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  padding: 0px 20px;
  background-color: #f7f7f7;
  border: 1px solid #e7e7e7;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 30px;
}

.contact-page .right-content form textarea {
  width: 100%;
  height: 130px;
  border-radius: 25px;
  padding: 20px 20px;
  background-color: #f7f7f7;
  border: 1px solid #e7e7e7;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 30px;
}

.contact-page .right-content form button {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  background-color: #ee626b;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0px 25px;
  border: none;
  border-radius: 25px;
  transition: all .3s;
}

.contact-page .right-content form button:hover {
  background-color: #0071f8;
}

/* 
---------------------------------------------
Responsive Style
--------------------------------------------- 
*/

body {
  overflow-x: hidden;
}
.dimage{
  text-align: center;
}
@media (max-width: 767px) {
  .right-image img{
    margin-top: 30px;
    height: 150px;
    width: 200px;
  }
  .dimage{
    height: 150px;
    width: 100px;
  }
  .dodo{
    
    height: 50px;
    /* width: 50px; */
  }
  .header-area {
    top: 0;
    box-shadow: 0px 6px 19px 1px black;
  }
  .main-banner .right-image span.offer {
    left: 20px;
    bottom: 20px;
  }
  .cta .subscribe form button {
    position: relative;
    width: 100%;
    margin-top: 15px;
  }
}

@media (max-width: 992px) {
  .header-area .main-nav .logo img {
    max-width: 110px;
  }
  .header-area .main-nav .nav li a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header-area .main-nav ul.nav {
    margin-top: 0px;
  }
  .background-header .main-nav ul.nav {
    margin-top: 20px;
  }
  .main-banner .caption {
    margin-bottom: 45px;
    text-align: center;
  }
  .main-banner .caption form,
  .main-banner .caption form input {
    max-width: 100%;
  }
  .features .item {
    margin-bottom: 30px;
  }
  .trending .main-button,
  .cta::after,
  .most-played .main-button,
  .related-games .main-button {
    display: none;
  }
  .most-played .section-heading {
    text-align: center;
  }
  .cta .shop {
    padding: 30px;
    margin-bottom: 30px;
  }
  .cta .subscribe {
    padding: 30px;
  }
  .cta .shop .section-heading {
    margin-right: 0px;
  }
  .trending ul.trending-filter li a {
    font-size: 13px;
    padding: 8px 10px;
  }
  .trending ul.trending-filter li {
    margin: 5px 2px;
  }
  .single-product .left-image {
    margin-right: 0px;
    margin-bottom: 45px;
  }
  .tabs-content .nav-link {
    font-size: 16px;
  }
  .tabs-content ul.nav-tabs li {
    margin-right: 15px;
    padding-right: 15px;
  }
  .page-heading {
    padding: 140px 0px 100px 0px;
  }
  .contact-page .right-content {
    margin-left: 0px;
    margin-top: 45px;
  }
}

@media (max-width: 1200px) {
  
}
