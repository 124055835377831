.bbody {
  /* height: 90vh; */
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  background-color: rgb(231, 231, 231);
}

.headerr {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.heading {
  color: green;
}

.title {
  font-weight: 400;
  letter-spacing: 1.5px;
}

.containerr {
  height: 600px;
  width: 500px;
  background-color: white;
  box-shadow: 8px 8px 20px rgb(128, 128, 128);
  position: relative;
  overflow: hidden;

  /* padding-left: 20%; */
}

.btnn {
  height: 60px;
  width: 300px;
  margin: 20px auto;
  box-shadow: 10px 10px 30px rgb(188, 198, 254);
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.login,
.signup {
  font-size: 22px;
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
}
.login:hover {
  font-size: 30px;
  border: 0;
  text-shadow: 2px 2px 2px black;
}
@keyframes blink {
  0% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully transparent */
  }
  100% {
    opacity: 1; /* Fully visible again */
  }
}

.blink {
  animation: blink 1s infinite; /* Blinking animation with a duration of 1 second */
}
.signup:hover {
  font-size: 30px;
  border: 0;
  text-shadow: 2px 2px 2px black;
}
.activeb {
  font-size: 30px;
  border: 0;
  text-shadow: 2px 2px 2px black;
}

.sliderr {
  height: 60px;
  width: 150px;
  border-radius: 50px;
  background-image: linear-gradient(
    to right,
    rgb(255, 195, 110),
    rgb(91, 168, 255)
  );
  position: absolute;
  top: 20px;
  left: 100px;
  transition: all 0.5s ease-in-out;
}

.moveslider {
  left: 250px;
}

.form-section {
  height: 300px;
  width: 1000px;
  padding: 20px 0;
  display: flex;
  position: relative;
  transition: all 0.5s ease-in-out;
  left: 0px;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  display: flex;
  border: 2px solid red;
}

.form-section-move {
  left: -500px;
}

.login-box,
.signup-box {
  height: 100%;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;
}

.login-box {
  gap: 50px;
}

.signup-box {
  gap: 30px;
}

.ele {
  height: 60px;
  width: 400px;
  outline: none;
  border: none;
  color: rgb(77, 77, 77);
  background-color: rgb(240, 240, 240);
  border-radius: 50px;
  padding-left: 30px;
  font-size: 18px;
}

.clkbtn {
  height: 60px;
  width: 150px;
  border-radius: 50px;
  background-image: linear-gradient(
    to right,
    rgb(20, 14, 199),
    rgb(112, 123, 185)
  );
  font-size: 22px;
  border: none;
  cursor: pointer;
}

/* For Responsiveness of the page */

@media screen and (max-width: 650px) {
  .containerrr {
    height: 600px;
    width: 300px;
    margin: 0 auto;
  }

  .title {
    font-size: 15px;
  }

  .btnn {
    height: 50px;
    width: 200px;
    margin: 20px auto;
  }

  .login,
  .signup {
    font-size: 19px;
  }

  .slider {
    height: 50px;
    width: 100px;
    left: 50px;
  }

  .moveslider {
    left: 150px;
  }

  .form-section {
    height: 500px;
    width: 600px;
    border: 2px solid red;
    text-align: center;
  }

  .form-section-move {
    left: -300px;
  }

  .login-box,
  .signup-box {
    height: 100%;
    width: 300px;
    margin: 0 auto;
    /* border: 2px solid red; */
  }

  .ele {
    height: 50px;
    width: 250px;
    font-size: 15px;
  }

  .clkbtn {
    height: 50px;
    width: 130px;
    font-size: 19px;
  }
}

@media screen and (max-width: 320px) {
  .containerr {
    height: 600px;
    width: 250px;
  }

  .heading {
    font-size: 30px;
  }

  .title {
    font-size: 10px;
  }

  .btnn {
    height: 50px;
    width: 200px;
    margin: 20px auto;
  }

  .login,
  .signup {
    font-size: 19px;
  }

  .slider {
    height: 50px;
    width: 100px;
    left: 27px;
  }

  .moveslider {
    left: 127px;
  }

  .form-section {
    height: 500px;
    width: 500px;
  }

  .form-section-move {
    left: -250px;
  }

  .login-box,
  .signup-box {
    height: 100%;
    width: 250px;
    margin: o auto;
  }

  .ele {
    height: 50px;
    width: 220px;
    font-size: 15px;
  }

  .clkbtn {
    height: 50px;
    width: 130px;
    font-size: 19px;
  }
}
